import axios from 'axios'
import router from '@/router/index'
import qs from 'qs'

// axios.defaults.baseURL="/api"
// axios.defaults.baseURL='http://localhost:8000/';
/**
 * 设置超时时间和跨域是否允许携带凭证
 */
// axios.defaults.timeout=10000  //设置十秒
// axios.defaults.withCredentials=true ;//例如：登录校验session和cookie

/**
 * 设置请求数据参数传递的格式，默认是json格式，但是在登录校验中后台一般设置请求格式：x-www-form-urlencoded(name=xxx,age=xxx)
 * 看服务器要求什么格式
 */
// axios.defaults.headers['Content-Type']='application/x-www-form-urlencoded' //声明请求格式
// axios.defaults.transformRequest=data=>qs.stringify(data)  //qs是第三方库，转换为x-www-form-urlencoded

/**
 * 设置请求拦截器：----在项目中发请求（请求没有发出去）可以做一些事情
 * 客户端->[请求拦截器]->服务器端
 * token校验（JWT）：接收到服务器的token,存储到vuex/本地存储中，每次向服务器发送请求，我们应该把token带上
 * config :发起请求的请求配置项
 */
axios.interceptors.request.use(config => {
    let token = localStorage.getItem('ms_token')
    // console.log("token:",token)
    // token && (config.headers.Authoriztion = 'Bearer ' + token)
    // config.headers.Authorization = `token ${localStorage.getItem('ms_token')}`;
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('ms_token');
    // console.log("config.headers.Authorization:",config.headers.Authorization)
    return config
}, error => {
    return Promise.reject(error)
})
/**
 * 设置响应拦截器
 * 服务器端返回信息->[响应拦截器]->客户端js获取到信息
 * response中包含属性：
 * data：相应数据,status:响应状态码,statusText：响应状态信息,headers：响应头,config：响应提供的配置信息,request
 */
axios.interceptors.response.use(
    (response) => {
        // console.log("use response:",response)
        console.log('状态码:', response.status);
        console.log("use response.data.code:",response.data.code)
        if(response.data.code !== 0){
            router.push('/login')
        }
        return response.data //将主体内容返回  axios.get().then(result=>{拿到的就是响应主体})
    },
    // error => {
    //     // console.log("error1 :",error)
    //     let {response} = error
    //     // 如果有返回结果
    //     if (response) {
    //         // console.log("response.status:",response.status)
    //         switch (response.status) {
    //             //这里面根据公司需求进行写
    //             case 404:
    //                 //进行错误跳转之类
    //                 break;
    //         }
    //     } else {
    //         // console.log("ffffffff1")
    //         //服务器没有返回结果 分两种情况 断网  服务器崩了
    //         if (!window.navigator.onLine) {
    //             //断网处理：跳转到断网页面
    //             return
    //         }
    //         return Promise.reject(error)
    //     }
    // }

    (error) => {
        // console.log("error:",error)
        // console.log("error.response:",error.response)
        // if (error.response) {
        //     console.log("error.response.status:",error.response.status)
        //     const res = error.response.data
        //     console.log("error.response.data:",res)
        //     if(res === '')
        //         router.push('/login')
        //     if(res.code !== 0)
        //         router.push('/login')
        //     // if (res.code === '10101') {
        //     //     router.push('/login')
        //     // }else if (res.code === '10102') {
        //     //     router.push('/login')
        //     // }else if (res.code === '10103') {
        //     //     router.push('/login')
        //     // }else if (res.code === '10104') {
        //     //     router.push('/login')
        //     // }else if (res.code === '10105') {
        //     //     router.push('/login')
        //     // }else if (res.code === '10106') {
        //     //     router.push('/login')
        //     // }
        // }
        //
        // return Promise.reject(error)
        // 对错误响应进行处理
        if (error.response) {
            console.log('错误状态码:', error.response.status);
            console.log('错误信息:', error.response.data);
        } else if (error.request) {
            console.log('未收到响应:', error.request);
        } else {
            console.log('请求错误:', error.message);
        }
        return Promise.reject(error);
    }

)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    // console.log("params:",params)
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res);
            // resolve(res.data);
        }).catch(err => {
            console.log("get err:",err)
            reject(err)
            // reject(err.data)
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    // console.log("params:", params)
    return new Promise((resolve, reject) => {
        // axios.post(url, QS.stringify(params))
        axios.post(url, (params))
            .then(res => {
                // console.log("axios.res:",res)
                resolve(res);
                // resolve(res.data);
            })
            .catch(err => {
                // console.log("axios.err:",err)
                reject(err)
                // reject(err.data)
            })
    });
}
/**
 * postForm方法，对应postForm请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function postForm(url, params) {
    // console.log("params:", params)
    return new Promise((resolve, reject) => {
        // axios.post(url, QS.stringify(params))
        axios.postForm(url, (params))
            .then(res => {
                // console.log("axios.res:",res)
                resolve(res);
                // resolve(res.data);
            })
            .catch(err => {
                // console.log("axios.err:",err)
                reject(err)
                // reject(err.data)
            })
    });
}
/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function remove(url, params) {
    // console.log("remove params:", params)
    return new Promise((resolve, reject) => {
        // axios.post(url, QS.stringify(params))
        axios.delete(url, (params))
            .then(res => {
                // console.log("axios.res:",res)
                resolve(res);
                // resolve(res.data);
            })
            .catch(err => {
                // console.log("axios.err:",err)
                reject(err)
                // reject(err.data)
            })
    });
}
/**
 * put方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    // console.log("patch params:", params)
    return new Promise((resolve, reject) => {
        // axios.post(url, QS.stringify(params))
        axios.put(url, (params))
            .then(res => {
                // console.log("axios.res:",res)
                resolve(res);
                // resolve(res.data);
            })
            .catch(err => {
                // console.log("axios.err:",err)
                reject(err)
                // reject(err.data)
            })
    });
}
/**
 * patch方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function patch(url, params) {
    // console.log("patch params:", params)
    return new Promise((resolve, reject) => {
        // axios.post(url, QS.stringify(params))
        axios.patch(url, (params))
            .then(res => {
                // console.log("axios.res:",res)
                resolve(res);
                // resolve(res.data);
            })
            .catch(err => {
                // console.log("axios.err:",err)
                reject(err)
                // reject(err.data)
            })
    });
}
export const getUsers = p => get('/api/users',p)
export const getUserInfo = p => get('/api/user/getuserinfo',p)
export const getUserImage = p => get('/api/user/getuserimage',p)

export const login = p => post('/api/login', p)
export const register = p => post('/api/register', p)
export const updateUserProfilesInfo = p => post ('/api/user/updateuserinfo', p)
export const updateUserProfilesImg = p => post ('/api/user/updateuserimg', p)
export const resetPassword2 = p => post('/api/changepassword2', p)


// 工程参数相关
export const getStudentInfo = p => post('/api/profiles/student/info', p)
export const removeStudent = p => remove ('/api/profiles/student', p)
export const truncateStudent = p => remove ('/api/profiles/truncatestudent', p)
export const updateStudent = p => post ('/api/profiles/student/updateinfo', p)

export const getTeacherInfo = p => post('/api/profiles/teacher/info', p)
export const removeTeacher = p => remove ('/api/profiles/teacher', p)
export const truncateTeacher = p => remove ('/api/profiles/truncateteacher', p)
export const updateTeacher = p => post ('/api/profiles/teacher/updateinfo', p)

export const getManagerInfo = p => post('/api/profiles/manager/info', p)
export const getInfo = p => post('/api/profiles/manager/info', p)
export const removeManager = p => remove ('/api/profiles/manager', p)
export const truncateManager = p => remove ('/api/profiles/truncatemanager', p)
export const updateManager = p => post ('/api/profiles/manager/updateinfo', p)

export const getGuestsInfo = p => get('/api/guests/info', p)
export const updateGuest = p => post('/api/guests/update', p)
export const destroyGuest = p => post('/api/guests/destroy', p)
export const deleteGuest = p => post('/api/guests/delete', p)
export const resetPassword = p => post('/api/guests/resetpassword', p)
export const modifyName = p => post('/api/guests/modifyname', p)

export const getKeyLexile = p => get('/api/course/key/lexile',p)
export const getAllKeyLexile = p => get('/api/course/key/alllexile',p)
export const updateKeyLexile = p => post ('/api/course/key/lexile', p)
export const removeKeyLexile = p => remove ('/api/course/key/lexile', p)
export const getKeyCourse = p => get('/api/course/key/course',p)
export const getAllKeyCourse = p => get('/api/course/key/allcourse',p)
export const updateKeyCourse = p => post ('/api/course/key/course', p)
export const removeKeyCourse = p => remove ('/api/course/key/course', p)

export const getKeyUrls = p => get('/api/course/key/urls',p)
export const getKeyUrl = p => get('/api/course/key/url',p)
export const updateKeyUrl = p => post ('/api/course/key/url', p)
export const removeKeyUrl = p => remove ('/api/course/key/url', p)

export const getKeyLesson = p => get('/api/course/key/lesson',p)
export const getAllKeyLesson = p => get('/api/course/key/alllesson',p)
export const updateKeyLesson = p => post ('/api/course/key/lesson', p)
export const removeKeyLesson = p => remove ('/api/course/key/lesson', p)

export const getClassroomInfo = p => get('/api/classroom/classinfo',p)
export const getAllClassroomInfo = p => get('/api/classroom/allclassinfo',p)
export const updateClassroomInfo = p => post ('/api/classroom/classinfo', p)
export const removeClassroomInfo = p => remove ('/api/classroom/classinfo', p)

export const getCityInfo = p => get('/api/city/cityinfo',p)
export const getAllCityInfo = p => get('/api/city/allcityinfo',p)
export const updateCityInfo = p => post ('/api/city/cityinfo', p)
export const removeCityInfo = p => remove ('/api/city/cityinfo', p)

export const getCampusInfo = p => get('/api/campus/info',p)
export const getAllCampusInfo = p => get('/api/campus/allinfo',p)
export const updateCampusInfo = p => post ('/api/campus/info', p)
export const removeCampusInfo = p => remove ('/api/campus/info', p)

export const getCourseCustomInfo = p => get('/api/coursecustom/info',p)
export const getAllCourseCustomInfo = p => get('/api/coursecustom/allinfo',p)
export const updateCourseCustomInfo = p => post ('/api/coursecustom/info', p)
export const removeCourseCustomInfo = p => remove ('/api/coursecustom/info', p)

export const getGroups = p => get('/api/groups/info',p)
export const getGroupStudents = p => get('/api/groups/students',p)
export const getAllGroups = p => get('/api/groups/allinfo',p)
export const updateGroups = p => post ('/api/groups/updateinfo', p)
export const startGroup = p => post ('/api/groups/startgroup', p)
export const finishedGroup = p => post ('/api/groups/finishedgroup', p)
export const removeGroups = p => remove ('/api/groups/info', p)

export const updateStudentContract = p => post ('/api/groups/updateStudentContract', p)
export const deleteStudentContract = p => post ('/api/groups/deletestudentcontract', p)

export const getManagerSummary = p => get('/api/summary/manager', p)
export const removeManagerSummary = p => remove ('/api/summary/manager', p)
export const updateManagerSummary = p => post ('/api/summary/manager', p)

export const getTeacherSummary = p => get('/api/summary/teacher', p)
export const removeTeacherSummary = p => remove ('/api/summary/teacher', p)
export const updateTeacherSummary = p => post ('/api/summary/teacher', p)

export const getStudentSummary = p => get('/api/summary/student', p)
export const removeStudentSummary = p => remove ('/api/summary/student', p)
export const updateStudentSummary = p => post ('/api/summary/student', p)

export const updateStudentPicture = p => post ('/api/picture/student', p)

export const getTeacherTimezone = p => get ('/api/teaching/gettimezone', p)
export const updateHolidays = p => post ('/api/teaching/updateholidays', p)
export const getDbHoliday = p => get('/api/teaching/getholidays', p)

export const getClassInfo = p => get('/api/class/classinfo',p)
export const getAllClassInfo = p => get('/api/class/allclassinfo',p)
export const createClassTab = p => post('/api/class/createtab',p)

export const getHomework = p => get('/api/learn/gethomework',p)
export const getHomeworkStudent = p => get('/api/learn/gethomeworkstudent',p)
export const newHomework = p => post('/api/learn/newhomework',p)
export const updateHomeworkStudent = p => post('/api/learn/updatehomeworkstudent',p)
//临时使用
export const importTeacherProfiles = p => get('/api/profiles/teacher/importprofiles',p)
export const importStudentProfiles = p => get('/api/profiles/student/importprofiles',p)
export const importManagerProfiles = p => get('/api/profiles/manager/importprofiles',p)

export const gestLinkStudentProfiles = p => get('/api/guests/student/linkprofiles',p)

//临时
export const createAllNull = p => post ('/api/summary/createAllNull', p)

export const getAllIpType = p => get('/api/ip/type/alliptype',p)
export const getIpType = p => get('/api/ip/type/iptype',p)
export const updateIpType = p => post ('/api/ip/type/iptype', p)
export const removeIpType = p => remove ('/api/ip/type/iptype', p)
export const getAllIpName = p => get('/api/ip/name/allipname',p)
export const getIpName = p => get('/api/ip/name/ipname',p)
export const updateIpName = p => post ('/api/ip/name/ipname', p)
export const removeIpName = p => remove ('/api/ip/name/ipname', p)
